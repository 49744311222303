import * as globalTypes from "../../globals/globalTypes";

// see FactsArticles

const insanityArticles: globalTypes.Article[] = [
  {
    index: 0,
    title: "Plenty of food, but shelves are empty",
    tag: "insEmpty",
    link: "We have food, but no toilet paper",
    body: `

    `
  },
  {
    index: 1,
    title: "Something else silly",
    tag: "insSilly",
    link: "Something else silly",
    body: `
    `
  },
];

export default insanityArticles;
