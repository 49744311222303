import * as globalTypes from "../../globals/globalTypes";

// see FactsArticles

const alternativesArticles: globalTypes.Article[] = [
  {
    index: 0,
    title: "Newspaper and other sources of paper",
    tag: "altPaper",
    link: "Use paper instead",
    body: `

    `
  },
  {
    index: 1,
    title: "Water",
    tag: "altWater",
    link: "Wash instead",
    body: `
    `
  },
  {
    index: 2,
    title: "Natural solutions",
    tag: "altNature",
    link: "Let nature give you a helping hand",
    body: `
    `
  },
];

export default alternativesArticles;
