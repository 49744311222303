import * as globalTypes from "../globals/globalTypes";

export const allJokes: globalTypes.JokeList = [ 
"These are great jokes, I think we're on a roll",

"Come on now, I never told you that these were good jokes",

`Why couldn't the toilet paper cross the road?

Because it got stuck in a crack`,

`Why doesn’t KFC have toilet paper?

Because it’s finger licking good`,

`Why am I not afraid of running out of toilet paper?

Because I still have some left from y2k`,

`What's the difference between a shower curtain and toilet paper?


You don't know?


So it was you!!!!`,

`I recently bought a toilet brush


Long story short, I’m going back to toilet paper`,

`I have a 24 pack of toilet rolls


Looking to swap for a 4 bedroom house`,

`Checking Email is like wiping your butt and checking the toilet paper....


Sometimes there's something new and sometimes there isn't`,

`I met a guy who works at a toilet paper company


He was quite Charmin, I must say`,

`What do you call Sean Connery inside a toilet stall without any toilet paper?


"A Shituation."`,

`I may not know how to bake toilet paper


But I do know how to brown one side`,

`Why did the toilet paper cross the road? 


To wipe the chickens butt!`,

`Cheap toilet paper is like John Wayne


It’s rough, tough, leaves a mark, and doesn’t take shit from anybody`,

`Makes perfect sense to me that everyone is panic buying toilet roll because of the Coronavirus...


One sneeze and everyone shits themselves!`,

`What is the difference between good and bad toilet paper?


One is terrible the other is tearable.`,

`What do you find on a FBI toilet?


Secret shit`,

`What did one piece of toilet paper say to the other? 


“I feel really wiped.”`,

`Its embarrassing when there is no toilet paper and you need to go and get one with your pants down,

 lucky enough the store is just round the corner.`,

`I ain’t shaking any ones hand, not because of the Coronavirus… 

I ain’t shaking any ones hand because y’all out of toilet paper!`,

`Chuck Norris doesn't have have to flush the toilet!


he scares the shit out of it`,

`Toilet paper is nearly worthless, but you know what is even more worthless?


My high school diploma`,

`I can’t stop making bad toilet paper jokes.


Sorry for the shitty joke.`,

`I actually really like single ply toilet paper...


It helps me stay in touch with my inner self`,

`I don’t always use toilet paper but when I do...


I use a shit ton`,

`I read a sign in a bathroom stall that said, “Do not flush foreign bodies. Toilet paper only.”


Beneath it someone had written, “No shit?”`,

`I actually really like single ply toilet paper...


It helps me stay in touch with my inner self`,

`Two rolls of toilet paper walked into a bar.


...one ran out.`,

`What did the poker player do with the last piece of toilet paper?


Fold`,

`How is the starship Enterprise like toilet paper?


It cruises Uranus, and wipes out Klingons`,

`How is life like toilet paper?


You’re either on a roll or taking shit from someone`,

`So many people are stockpiling toilet paper because of the Coronavirus


They must be shitting themselves`,

`If you need 200 toilet rolls for quarantine, then you should have seen a doctor a long time ago`,

`I can't believe I pay so much money per year for toilet paper


It's a total rip-off`
];

/* Sources
  https://upjoke.com/toilet-paper-jokes
*/